<script setup lang="ts">
import * as d3 from 'd3';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { OctagonAlert, Zap } from 'lucide-vue-next';

import 'dayjs/locale/fr';
import 'dayjs/locale/en';

// Composables
const { t } = useI18n();
const { locale } = useI18n();

export interface DataPoint {
  date: Date;
  derives: number;
  gains: number;
}
export interface Shift {
  start: Date;
  end: Date;
  name: string;
}
export interface Drift {
  start: Date;
  end: Date;
}
export interface Win {
  start: Date;
  end: Date;
  name: string;
}

// Props
const emit = defineEmits<{
  (e: 'drift-click', drift: Drift): void;
  (e: 'win-click', win: Win): void;
  (e: 'shift-click', shift: Shift): void;
}>();
const props = defineProps<{
  data: DataPoint[];
  shifts?: Shift[];
  drifts?: Drift[];
  wins?: Win[];
  soft?: boolean;
  loading?: boolean;
}>();

// Data
const container = ref<HTMLDivElement | null>(null);
const tooltip = ref<HTMLDivElement | null>(null);
const maxYLabelWidth = ref(0);
const xAxisLabelsHeight = ref(0);
const width = ref(0);
const height = ref(600);

// Methods
const formatDate = (date: Date) => {
  if (props.soft) {
    return dayjs(date).format('ddd DD');
  }
  return dayjs(date).format('ddd DD HH:mm');
};

const updateDimensions = () => {
  if (!container.value) return;
  const rect = container.value.getBoundingClientRect();
  width.value = rect.width;
};

const createChart = () => {
  if (!container.value || !props.data.length || props.loading) return;

  updateDimensions();

  d3.select(container.value).selectAll('*').remove();

  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.fontSize = '12px';
  tempDiv.style.fontFamily = 'system-ui, -apple-system, sans-serif';
  document.body.appendChild(tempDiv);

  const yValues = props.data.map((d) => Math.max(Math.abs(d.derives), Math.abs(d.gains)));
  const maxYValue = Math.max(...yValues);

  const tempYScale = d3.scaleLinear().domain([-maxYValue, maxYValue]);
  const ticks = tempYScale.ticks();

  let maxWidth = 0;

  ticks.forEach((tick) => {
    tempDiv.textContent = Math.abs(tick).toString();
    maxWidth = Math.max(maxWidth, tempDiv.offsetWidth);
  });

  tempDiv.textContent = t('global.drifts');
  maxWidth = Math.max(maxWidth, tempDiv.offsetWidth);

  tempDiv.textContent = t('global.wins');
  maxWidth = Math.max(maxWidth, tempDiv.offsetWidth);

  const labelPadding = 10;
  maxYLabelWidth.value = maxWidth + labelPadding;

  document.body.removeChild(tempDiv);

  const margin = {
    top: 20,
    right: 0,
    bottom: xAxisLabelsHeight.value + 20,
    left: maxYLabelWidth.value,
  };

  const chartWidth = width.value - margin.left - margin.right;
  const chartHeight = height.value - margin.top - margin.bottom;

  const svg = d3
    .select(container.value)
    .append('svg')
    .attr('width', width.value)
    .attr('height', height.value)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const xScale = d3
    .scaleTime()
    .domain([d3.min(props.data, (d) => d.date) as Date, d3.max(props.data, (d) => d.date) as Date])
    .range([0, chartWidth]);

  const maxPositive = d3.max(props.data, (d) => Math.max(d.derives, d.gains)) as number;
  const maxNegative = Math.abs(d3.min(props.data, (d) => Math.min(d.derives, d.gains)) as number);

  const maxValue = Math.max(maxPositive, maxNegative);

  const domainPadding = maxValue * 0.5;
  const totalDomain = maxValue + domainPadding;

  const yScale = d3.scaleLinear().domain([-totalDomain, totalDomain]).range([chartHeight, 0]);

  const defs = svg.append('defs');

  const deriveGradient = defs
    .append('linearGradient')
    .attr('id', 'derive-gradient')
    .attr('x1', '0')
    .attr('y1', '0')
    .attr('x2', '0')
    .attr('y2', '1');
  deriveGradient.append('stop').attr('offset', '0%').attr('stop-color', '#ef4444').attr('stop-opacity', 0.2);
  deriveGradient.append('stop').attr('offset', '100%').attr('stop-color', '#ef4444').attr('stop-opacity', 0);

  const gainsGradient = defs
    .append('linearGradient')
    .attr('id', 'gains-gradient')
    .attr('x1', '0')
    .attr('y1', '1')
    .attr('x2', '0')
    .attr('y2', '0');
  gainsGradient.append('stop').attr('offset', '0%').attr('stop-color', '#22c55e').attr('stop-opacity', 0.2);
  gainsGradient.append('stop').attr('offset', '100%').attr('stop-color', '#22c55e').attr('stop-opacity', 0);

  const backgroundGroup = svg.append('g').attr('class', 'background-group');
  const interactiveGroup = svg.append('g').attr('class', 'interactive-group');

  const RECT_GAP = 6;

  if (props.shifts) {
    backgroundGroup
      .selectAll('rect')
      .data(props.shifts)
      .join('rect')
      .attr('x', (d, i) => {
        if (i === 0) return xScale(d.start);
        return xScale(d.start) + RECT_GAP / 2;
      })
      .attr('y', 0)
      .attr('width', (d, i, nodes) => {
        if (i === 0) {
          return xScale(d.end) - xScale(d.start) - RECT_GAP / 2;
        }
        if (i === nodes.length - 1) {
          return xScale(d.end) - xScale(d.start) - RECT_GAP / 2;
        }
        return xScale(d.end) - xScale(d.start) - RECT_GAP;
      })
      .attr('height', chartHeight)
      .attr('rx', 6)
      .attr('ry', 6)
      .attr('stroke', '#f8f9fa')
      .attr('fill', 'transparent')
      .attr('class', 'day-rect-background');

    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', chartWidth)
      .attr('y1', yScale(0))
      .attr('y2', yScale(0))
      .attr('stroke', '#9ca3af')
      .attr('stroke-width', 1);

    interactiveGroup
      .selectAll('rect')
      .data(props.shifts)
      .join('rect')
      .attr('x', (d, i) => {
        if (i === 0) return xScale(d.start);
        return xScale(d.start) + RECT_GAP / 2;
      })
      .attr('y', 0)
      .attr('width', (d, i, nodes) => {
        if (i === 0) {
          return xScale(d.end) - xScale(d.start) - RECT_GAP / 2;
        }
        if (i === nodes.length - 1) {
          return xScale(d.end) - xScale(d.start) - RECT_GAP / 2;
        }
        return xScale(d.end) - xScale(d.start) - RECT_GAP;
      })
      .attr('height', chartHeight)
      .attr('rx', 6)
      .attr('ry', 6)
      .attr('fill', 'transparent')
      .attr('class', 'day-rect-interactive')
      .style('cursor', 'pointer')
      .on('click', (event: MouseEvent, d: Shift) => {
        emit('shift-click', d);
      })
      .on('mouseover', function (event: MouseEvent, d) {
        if (tooltip.value) {
          const rect = (event.target as SVGRectElement).getBoundingClientRect();
          const rectCenterX = rect.left + rect.width / 2;

          tooltip.value.classList.add('no-transition');
          tooltip.value.classList.remove('tooltip-visible');
          tooltip.value.classList.add('tooltip-hidden');
          tooltip.value.style.left = `${rectCenterX}px`;
          tooltip.value.style.top = `${rect.top - 50}px`;
          tooltip.value.textContent = d.name;

          tooltip.value.offsetHeight;

          tooltip.value.classList.remove('no-transition');
          tooltip.value.classList.remove('tooltip-hidden');
          tooltip.value.classList.add('tooltip-visible');

          backgroundGroup
            .selectAll('rect')
            .filter((shift) => shift === d)
            .attr('fill', '#f8f9fa');
        }
      })
      .on('mouseout', function (event, d) {
        if (tooltip.value) {
          tooltip.value.classList.remove('tooltip-visible');
          tooltip.value.classList.add('tooltip-hidden');

          backgroundGroup
            .selectAll('rect')
            .filter((shift) => shift === d)
            .attr('fill', 'transparent');
        }
      });
  }

  const smoothStep = (points: [number, number][]) => {
    const path = d3.path();
    points.forEach((point, i) => {
      if (i === 0) {
        path.moveTo(point[0], point[1]);
      } else {
        const prev = points[i - 1];
        const x0 = prev[0];
        const y0 = prev[1];
        const x1 = point[0];
        const y1 = point[1];

        if (y0 !== y1) {
          path.lineTo(x0, y0);
          path.lineTo(x0, y1);
          path.lineTo(x1, y1);
        } else {
          path.lineTo(x1, y1);
        }
      }
    });
    return path.toString();
  };

  const lineDerive = (data: DataPoint[]) => {
    const points = data.map((d) => [xScale(d.date), yScale(d.derives)] as [number, number]);
    return smoothStep(points);
  };

  const lineGains = (data: DataPoint[]) => {
    const points = data.map((d) => [xScale(d.date), yScale(d.gains)] as [number, number]);
    return smoothStep(points);
  };

  const areaDerive = (data: DataPoint[]) => {
    const topPoints = data.map((d) => [xScale(d.date), yScale(d.derives)] as [number, number]);
    const bottomPoints = data.map((d) => [xScale(d.date), yScale(0)] as [number, number]);
    return `${smoothStep(topPoints)} L ${bottomPoints
      .reverse()
      .map((p) => p.join(','))
      .join(' L ')} Z`;
  };

  const areaGains = (data: DataPoint[]) => {
    const topPoints = data.map((d) => [xScale(d.date), yScale(d.gains)] as [number, number]);
    const bottomPoints = data.map((d) => [xScale(d.date), yScale(0)] as [number, number]);
    return `${smoothStep(topPoints)} L ${bottomPoints
      .reverse()
      .map((p) => p.join(','))
      .join(' L ')} Z`;
  };

  const yAxis = d3.axisLeft(yScale).tickFormat((d: number) => Math.abs(d).toString());

  svg
    .append('g')
    .attr('class', 'text-gray-400')
    .call(yAxis)
    .call((g: d3.Selection<SVGGElement, unknown, null, undefined>) => {
      g.select('.domain').remove();

      // Positionne "Dérives" en haut
      const derivesText = g.selectAll('.tick:last-of-type text').text(t('global.drifts')).style('color', '#9ca3af');
      const derivesWidth = (derivesText.node() as SVGTextElement).getComputedTextLength();
      derivesText.attr('x', -10 - derivesWidth).attr('text-anchor', 'start');

      // Positionne "Gains" en bas
      const gainsText = g.selectAll('.tick:first-of-type text').text(t('global.wins')).style('color', '#9ca3af');
      const gainsWidth = (gainsText.node() as SVGTextElement).getComputedTextLength();
      gainsText.attr('x', -10 - gainsWidth).attr('text-anchor', 'start');
    })
    .call((g: d3.Selection<SVGGElement, unknown, null, undefined>) => {
      if (props.soft) {
        g.selectAll('.tick line').attr('x2', chartWidth).attr('stroke', '#f3f4f6');
      } else {
        g.selectAll('.tick line').remove();
      }
    })
    .selectAll('text:not(:first-child):not(:last-child)')
    .style('color', '#9ca3af')
    .each(function () {
      const textWidth = (this as SVGTextElement).getComputedTextLength();
      d3.select(this)
        .attr('x', -10 - textWidth)
        .attr('text-anchor', 'start');
    });

  svg
    .append('path')
    .datum(props.data)
    .attr('class', 'area-derives')
    .attr('fill', 'url(#derive-gradient)')
    .attr('d', () => areaDerive(props.data))
    .style('pointer-events', 'none');

  svg
    .append('path')
    .datum(props.data)
    .attr('class', 'area-gains')
    .attr('fill', 'url(#gains-gradient)')
    .attr('d', () => areaGains(props.data))
    .style('pointer-events', 'none');

  svg
    .append('path')
    .datum(props.data)
    .attr('class', 'line-derives')
    .attr('fill', 'none')
    .attr('stroke', '#ef4444')
    .attr('stroke-width', 1.5)
    .attr('d', () => lineDerive(props.data))
    .style('pointer-events', 'none');

  svg
    .append('path')
    .datum(props.data)
    .attr('class', 'line-gains')
    .attr('fill', 'none')
    .attr('stroke', colors.green[500])
    .attr('stroke-width', 1.5)
    .attr('d', () => lineGains(props.data))
    .style('pointer-events', 'none');

  const xAxis = d3.axisBottom(xScale).tickFormat((d: Date) => formatDate(d));

  svg
    .append('g')
    .attr('transform', `translate(0,${chartHeight})`)
    .attr('class', 'text-gray-500')
    .call(xAxis)
    .call((g) => g.select('.domain').remove())
    .call((g) => g.selectAll('.tick line').remove())
    .selectAll('text')
    .style('color', '#9ca3af')
    .style('font-size', '12px')
    .style('text-anchor', props.soft ? 'middle' : 'end')
    .attr('dx', props.soft ? '0' : '-0.8em')
    .attr('dy', props.soft ? '1em' : '0.15em')
    .attr('transform', props.soft ? 'rotate(0)' : 'rotate(-90)');
};

const calculateXAxisHeight = () => {
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  tempDiv.style.fontSize = '12px';
  tempDiv.style.fontFamily = 'system-ui, -apple-system, sans-serif';
  tempDiv.innerHTML = formatDate(new Date());
  document.body.appendChild(tempDiv);

  xAxisLabelsHeight.value = props.soft ? tempDiv.offsetHeight + 10 : tempDiv.offsetWidth;
  document.body.removeChild(tempDiv);
};

const resizeObserver = new ResizeObserver(() => {
  updateDimensions();
  createChart();
});

const getDriftPosition = (drift: Drift) => {
  if (!container.value) return {};

  const margin = { left: maxYLabelWidth.value + 15, top: 20 };
  const chartWidth = width.value - margin.left;

  const timeScale = d3
    .scaleTime()
    .domain([d3.min(props.data, (d) => d.date) as Date, d3.max(props.data, (d) => d.date) as Date])
    .range([0, chartWidth]);

  const startX = timeScale(drift.start) + margin.left;
  const endX = timeScale(drift.end) + margin.left;
  const driftWidth = Math.max(endX - startX, 32);

  return {
    left: `${startX}px`,
    top: '32px',
    width: `${driftWidth}px`,
  };
};

const getWinPosition = (win: Win) => {
  if (!container.value) return {};

  const margin = {
    top: 20,
    right: 0,
    bottom: xAxisLabelsHeight.value + 32,
    left: maxYLabelWidth.value + 15,
  };

  const chartWidth = width.value - margin.left;

  const timeScale = d3
    .scaleTime()
    .domain([d3.min(props.data, (d) => d.date) as Date, d3.max(props.data, (d) => d.date) as Date])
    .range([0, chartWidth]);

  const startX = timeScale(win.start) + margin.left;
  const endX = timeScale(win.end) + margin.left;
  const winWidth = Math.max(endX - startX, 32);

  return {
    left: `${startX}px`,
    bottom: `${margin.bottom}px`,
    width: `${winWidth}px`,
  };
};

// Lifecycle
onMounted(() => {
  if (container.value) {
    resizeObserver.observe(container.value);
    calculateXAxisHeight();
    createChart();
  }
});

watch(
  locale,
  (newLocale) => {
    dayjs.locale(newLocale);
    nextTick(() => {
      calculateXAxisHeight();
      createChart();
    });
  },
  { immediate: true },
);

watch(
  [() => props.data, () => props.soft, locale],
  () => {
    nextTick(() => {
      calculateXAxisHeight();
      createChart();
    });
  },
  { deep: true },
);

watch(width, () => {
  if (props.drifts.length || props.wins.length) {
    nextTick(() => {
      createChart();
    });
  }
});

onUnmounted(() => {
  resizeObserver.disconnect();
});
</script>

<template>
  <div class="relative w-full h-full">
    <div ref="container" class="chart-container w-full h-full"></div>
    <div ref="tooltip" class="tooltip tooltip-hidden fixed bg-black text-white px-3 py-2 rounded-md text-sm" style="z-index: 1000"></div>

    <div v-if="loading" class="flex justify-center items-center min-h-[400px]">
      <ui-loader />
    </div>

    <template v-else>
      <div
        v-for="drift in drifts"
        :key="`${drift.start}-${drift.end}`"
        class="absolute flex items-center group cursor-pointer"
        :style="getDriftPosition(drift)"
        @click="emit('drift-click', drift)"
      >
        <div class="h-[15px] w-[2px] bg-red-500 rounded-md"></div>
        <div class="h-[2px] flex-1 w-[90px] bg-red-500 rounded-md"></div>
        <div class="bg-red-50 py-[4px] px-[6px] group-hover:bg-red-500 rounded">
          <OctagonAlert class="w-[16px] h-[16px] group-hover:text-white text-red-500" />
        </div>
        <div class="h-[2px] flex-1 w-[90px] bg-red-500 rounded-md"></div>
        <div class="h-[15px] w-[2px] bg-red-500 rounded-md"></div>
      </div>

      <div
        v-for="win in wins"
        :key="`${win.start}-${win.end}`"
        class="absolute flex items-center cursor-pointer group"
        :style="getWinPosition(win)"
        @click="emit('win-click', win)"
      >
        <div class="h-[15px] w-[2px] bg-primary-500 rounded-md"></div>
        <div class="h-[2px] flex-1 w-[90px] bg-primary-500 rounded-md"></div>
        <div class="bg-green-50 group-hover:bg-primary-500 py-[4px] px-[6px] rounded">
          <Zap class="w-[16px] h-[16px] group-hover:text-white text-green-500" />
        </div>
        <div class="h-[2px] flex-1 w-[90px] bg-primary-500 rounded-md"></div>
        <div class="h-[15px] w-[2px] bg-primary-500 rounded-md"></div>
      </div>
    </template>
  </div>
</template>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
  background-color: white;
}

.tooltip {
  pointer-events: none;
  transform: translateX(-50%);
  transition: all 200ms ease-out;
}

.tooltip-visible {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.tooltip-hidden {
  opacity: 0;
  transform: translateX(-50%) translateY(-20px);
  visibility: hidden;
}

.no-transition {
  transition: none !important;
}

:deep(.text-gray-500) {
  color: #6b7280;
}

:deep(.tick text) {
  font-family:
    system-ui,
    -apple-system,
    sans-serif;
}

:deep(.day-rect-background) {
  transition: fill 100ms ease-in-out;
}

:deep(.day-rect-interactive) {
  opacity: 0;
}

:deep(.area-derives),
:deep(.area-gains),
:deep(.line-derives),
:deep(.line-gains) {
  pointer-events: none;
}

.drift-indicator {
  position: absolute;
  z-index: 10;
}
</style>
